@import './fonts/fonts.css';

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 251, 239);
  font-family: "Garet-Book", sans-serif;
}

button, input, textarea, select{
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  resize: none;
}

*{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
  outline: none;
  cursor: pointer;
  color: inherit;
}
