
@font-face {
    font-family: 'Montserrat Hairline';
    src: local('Montserrat Hairline'), local('Montserrat-Hairline'), url('montserrathairline.woff2') format('woff2'), url('montserrathairline.woff') format('woff'), url('montserrathairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light'), local('Montserrat-Light'), url('montserratlight.woff2') format('woff2'), url('montserratlight.woff') format('woff'), url('montserratlight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat-Regular'), url('montserrat.woff2') format('woff2'), url('montserrat.woff') format('woff'), url('montserrat.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url('montserratbold.woff2') format('woff2'), url('montserratbold.woff') format('woff'), url('montserratbold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black'), local('Montserrat-Black'), url('montserratblack.woff2') format('woff2'), url('montserratblack.woff') format('woff'), url('montserratblack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Russo One';
    src: local('Russo One'), local('RussoOne'), url('russoone.woff2') format('woff2'), url('russoone.woff') format('woff'), url('russoone.ttf') format('truetype');
    font-weight: 400;
    font-style: normal; 
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url('Robotocondensed.woff2') format('woff2'), url('Robotocondensed.woff') format('woff'), url('Robotocondensed.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Garet-Book';
    src: local('Garet-Book'), local('Garet-Book'), url('Garet-Book.otf') format('otf'), url('Garet-Book.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Garet-Heavy';
    src: local('Garet-Heavy'), local('Garet-Heavy'), url('Garet-Heavy.otf') format('otf'), url('Garet-Heavy.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Ailerons-Typeface';
    src: local('Ailerons-Typeface'), local('Ailerons-Typeface'), url('Ailerons-Typeface.otf') format('truetype');
    font-weight: 100;
    font-style: normal;
}